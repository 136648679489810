<template>
    <div class="container">
        <!-- <common-head :title="title" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="title"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div class="g_safe_top">
			<div class="user_msg user_msg999">
			    <div class="label_input">
			        <div class="label">{{$t('system.xianshimingcheng')}}</div>
			        <div class="input"><input type="txt" :placeholder="$t('system.qingshuruxianshimingcheng')" v-model="name" /></div>
			    </div>
			</div>
			<div class="user_msg">
			    <div class="xiala" @click="xiala">
			        <div class="label">{{ jiaoyisuo_name }}</div>
			        <div class="label1">{{ exe }}</div>
			        <div class="right_img" v-if="!exe">
			            <img src="@/assets/imgs/shangla.png" alt="" v-if="xiala_status" />
			            <img src="@/assets/imgs/xiala.png" alt="" v-else />
			        </div>
			    </div>
			    <div class="xiala_content" v-if="xiala_status">
			        <div class="option_select" v-for="(item, index) in jiaoyisuo_list" :key="index" @click="choose(index)">
			            <div class="left">{{ item }}</div>
			        </div>
			    </div>
			    <div class="xiala" @click="xiala1">
			        <div class="label">{{ jiaoyifangshi }}</div>
			        <div class="label1">{{ jiaoyi_type }}</div>
			        <div class="right_img" v-if="!jiaoyi_type">
			            <img src="@/assets/imgs/shangla.png" alt="" v-if="xiala_status1" />
			            <img src="@/assets/imgs/xiala.png" alt="" v-else />
			        </div>
			    </div>
			    <div class="xiala_content" v-if="xiala_status1">
			        <div class="option_select" v-for="(item, index) in jiaoyi_type_list" :key="index" @click="choose1(index)">{{ item }}</div>
			    </div>
			    <div class="label_input1">
			        <div class="label">{{$t('system.shoufeibili')}}（%）</div>
			        <div class="input"><input type="txt" :placeholder="$t('system.qingshurushoufeibili')" v-model="radio" /></div>
			    </div>
			    <div class="label_input1">
			        <div class="label">{{$t('system.zhanghuzongzichan')}}（USDT)</div>
			        <div class="input"><input type="txt" :placeholder="$t('system.qingshuruzhanghuzongzichan')" v-model="fund" /></div>
			    </div>
			</div>
			<div class="bottom"><div class="button" @click="Submit()">{{$t('system.tijioashenqing')}}</div></div>
		</div>
        
    </div>
</template>

<script>
// import TitleTwo from "../../components/titleTwo";
import commonHead from '../../components/commonHead';
export default {
    components: { commonHead },
    data() {
        return {
            title: this.$route.query.msg,
            name: '',
			jiaoyisuo_name: this.$t('system.xuanzhejiaoyisuo') + ':',
			jiaoyifangshi: this.$t('system.jiaoyifangshi') + ':',
            exe: '',
            jiaoyi_type: '',
			xiala_status: false,
			xiala_status1: false,
			jiaoyisuo_list: [],
			jiaoyi_type_list: [],
            brokerage_ratio: '',
            me_set_fund: '',
            radio: '',
            fund: '',
            index: '',
            index1: '',
            info: this.$route.query.info
        };
    },
    created() {
        console.log(this.$route.query.info);
        if (this.info) {
            this.name = this.$route.query.info.deposit_name;
            if (this.$route.query.info.exchange) {
                this.exe = this.$route.query.info.exchange.toUpperCase();
            }
            if (this.$route.query.info.deposit_type == 1) {
                this.jiaoyi_type = this.$t('system.Spot');
            } else if (this.$route.query.info.deposit_type == 2) {
                this.jiaoyi_type = this.$t('system.contract');
            }
            this.radio = this.$route.query.info.brokerage_ratio*100;
            this.fund = this.$route.query.info.me_set_fund;
        }
        this.getTrusteeshipRelevantConfig();
    },
    methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		xiala() {
		    this.xiala_status = !this.xiala_status;
		},
		xiala1() {
		    this.xiala_status1 = !this.xiala_status1;
		},
        choose(e) {
            this.xiala_status = !this.xiala_status;
            this.exe = this.jiaoyisuo_list[e];
        },
        choose1(e) {
            this.xiala_status1 = !this.xiala_status1;
            this.jiaoyi_type = this.jiaoyi_type_list[e];
        },
        Submit() {
            if (this.info) {
                if (this.info.state == 0 || this.info.state == 1) {
                    this.modifyBufferTeacher();
                } else {
                    this.modifyApplyTrusteeshipTeacher();
                }
            } else {
                this.applyTrusteeshipTeacher();
            }
        },
        //用户修改处于0未开启、1开启状态的托管
        modifyBufferTeacher() {
            if (this.jiaoyi_type == this.$t('system.Spot')) {
                this.index1 = 1;
            } else if (this.jiaoyi_type == this.$t('system.contract')) {
                this.index1 = 2;
            }
            this.$post2('Deposit/Api/Index/modifyBufferTeacher', {
                id: this.info.id,
                exchange: this.exe.toLowerCase(), //0 1
                deposit_type: this.index1,
                deposit_name: this.name,
                brokerage_ratio: this.radio / 100,
                me_set_fund: this.fund
            })
                .then(res => {
                    this.$toast(this.$t('system.xiugaichenggong'));
                    this.$router.push('/im_teacher');
                    // console.log(res);
                })
                .catch(e => {
					if(e == '收费率不合法') {
						this.$toast.fail(this.$t('system.shoufeilvbuhefa'));
					}else {
						this.$toast.fail(e);
					}
                });
        },
        //用户修改处于审核、取消托管申请、后台拒绝托管申请状态的托管
        modifyApplyTrusteeshipTeacher() {
            if (this.jiaoyi_type == this.$t('system.Spot')) {
                this.index1 = 1;
            } else if (this.jiaoyi_type == this.$t('system.contract')) {
                this.index1 = 2;
            }
            this.$post2('Deposit/Api/Index/modifyApplyTrusteeshipTeacher', {
                id: this.info.id,
                exchange: this.exe.toLowerCase(), //0 1
                deposit_type: this.index1,
                deposit_name: this.name,
                brokerage_ratio: this.radio / 100,
                me_set_fund: this.fund
            })
                .then(res => {
                    this.$toast(this.$t('system.xiugaichenggong'));
                    this.$router.push('/im_teacher');
                    // console.log(res);
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        },
        //获取交易所，托管类型
        getTrusteeshipRelevantConfig() {
            this.$post2('Deposit/Api/Index/getTrusteeshipRelevantConfig', {
				noToast: true
			})
                .then(res => {
					// console.log(res);
					let arr = Object.values(res.ExchangeEnMap);
					arr.forEach(item=> {
						this.jiaoyisuo_list.push(item.toUpperCase())
					})
					let arr1 = Object.values(res.DepositType);
					arr1.forEach(item=> {
						if(item ==1){
							item = this.$t('system.Spot')
						}else {
							item = this.$t('system.contract')
						}
						this.jiaoyi_type_list.push(item)
					})
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        },
        // 用户申请新套餐（即用户申请成为带单老师，用户点击原型图中新量化时提交数据的接口）
        applyTrusteeshipTeacher() {
            if (this.jiaoyi_type == this.$t('system.Spot')) {
                this.index1 = 1;
            } else if (this.jiaoyi_type == this.$t('system.contract')) {
                this.index1 = 2;
            }
            this.$post2('Deposit/Api/Index/applyTrusteeshipTeacher', {
                exchange: this.exe.toLowerCase(), //0 1
                deposit_type: this.index1,
                deposit_name: this.name,
                brokerage_ratio: this.radio / 100,
                me_set_fund: this.fund
            })
                .then(res => {
                    this.$router.push('./im_teacher');
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        }
    }
};
</script>

<style scoped lang="less">
.container {
    // background-color: #f8f8f8;
	font-family: PingFangSC-Regular;
    // height: calc(100vh + 110px);
    .content {
        // background-color: #f8f8f8;
        padding-bottom: 1rem;
    }
    .head_right {
        color: #ffffff;
        font-size: 14px;
    }
}
.user_msg {
    background-color: #ffffff;
    margin: 10px;
    border-radius: 8px;
    // box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
    font-family: PingFang SC;
    .label_input {
        margin: 0 0 0 0.2rem;
        padding: 0.39rem 0;
        display: flex;
        align-items: center;
        .label {
			font-family: PingFangSC-Medium;
            color: #333333;
            font-size: 0.28rem;
            text-align: left;
            font-weight: 550;
        }
		input::-webkit-input-placeholder {
		    /* 修改字体颜色 */
		    color: #999999;
		    /* 修改字号，默认继承input */
		    font-size: 12px;
		}
        .input {
            margin-left: 0.32rem;
            input {
                padding-right: 1rem;
                width: 100%;
                color: #333;
				font-size: 0.28rem;
                border: none;
            }
        }
    }
    .xiala {
        margin: 0 0.2rem;
        padding: 0.3rem 0;
        border-bottom: 0.5px solid rgba(211, 217, 222, 0.5);
        display: flex;
        justify-content: space-between;
        .label {
			font-family: PingFangSC-Medium;
            font-size: 0.3rem;
            color: #333333;
            font-weight: 550;
            text-align: left;
        }
        .label1 {
			font-family: PingFangSC-Medium;
            // margin-right: 3rem;
            font-size: 0.3rem;
            color: #333333;
            font-weight: 500;
            text-align: left;
        }
    }
    .xiala_content {
        margin: 0 0.2rem;
        font-size: 0.3rem;

        .option_select {
            border-bottom: 0.5px solid rgba(211, 217, 222, 0.5);
            padding: 0.2rem 0;
            display: flex;
            font-size: 0.28rem;
            justify-content: center;
        }
        .option_select:last-child {
            border-bottom: none;
        }
    }
    .label_input1 {
        margin: 0 0 0 0.16rem;
        padding: 0.28rem 0;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        border-bottom: 0.5px solid rgba(211, 217, 222, 0.5);
        .label {
			font-family: PingFangSC-Medium;
            color: #333333;
            font-size: 0.28rem;
            text-align: left;
            font-weight: 550;
        }
		input::-webkit-input-placeholder {
		    /* 修改字体颜色 */
		    color: #999999;
		    /* 修改字号，默认继承input */
		    font-size: 12px;
		}
        .input {
            margin-left: 0.22rem;
            input {
				font-size: .28rem;
                text-align: left;
                padding-right: 0.2rem;
                width: 100%;
                color: #333;
                border: none;
            }
        }
    }
}
.user_msg:last-child {
    padding-bottom: 0.4rem;
}
.bottom {
    position: fixed;
    left: 0;
    bottom: 0.2rem;
    padding: 0;
    .button {
        margin: 0.14rem 0.3rem 0 0.28rem;
        width: 6.7rem;
        height: 0.8rem;
        line-height: 0.8rem;
        background-color: #FAD538;
        color: #333333;
        font-size: 0.32rem;
        text-align: center;
        border-radius: 0.12rem;
        font-weight: 500;
    }
}
</style>
